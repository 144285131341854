import moment from 'moment-timezone';
import urljoin from 'url-join';

const {
    REACT_APP_URL_STORAGE,
    REACT_APP_URL_IMAGE,
} = process.env;

const svgType = 'image/svg';
export const imageTypesRegex = /image\/.+/; // qualquer imagem
export const documentsRegex = [
    /text\/.+/, // qualquer texto
    /application\/pdf/, // pdf
    /application\/xml/, // xml
    /application\/vnd\.ms-powerpoint/, // ppt
    /application\/vnd\.openxmlformats-officedocument\.presentationml\.presentation/, // pptx
    /application\/vnd\.ms-excel/, // xls
    /application\/vnd\.openxmlformats-officedocument\.spreadsheetml\.sheet/, // xlsx
    /application\/msword/, // doc
    /application\/vnd\.openxmlformats-officedocument\.wordprocessingml\.document/, // docx
    /application\/vnd\.oasis\.opendocument\..+/, // openoffice novo
    /application\/vnd\.sun\.xml\..+/, // openoffice antigo
];
export const typesRegex = [
    {
        thumb: require('~/assets/video-file-3-512.png').default,
        regex: /video\/.+/, // qualquer vídeo
    },
    {
        thumb: require('~/assets/audio-file-3-512.png').default,
        regex: /audio\/.+/, // qualquer aúdio
    },
    {
        thumb: require('~/assets/code-512.png').default,
        regex: /(application|text)\/xml/, // xml
    },
    {
        thumb: require('~/assets/document-512.png').default,
        regex: /text\/.+/, // qualquer texto
    },
    {
        thumb: require('~/assets/pdf-512.png').default,
        regex: /application\/pdf/, // pdf
    },
    {
        thumb: require('~/assets/powerpoint-3-512.png').default,
        regex: /application\/vnd\.ms-powerpoint/, // ppt
    },
    {
        thumb: require('~/assets/powerpoint-3-512.png').default,
        regex: /application\/vnd\.openxmlformats-officedocument\.presentationml\.presentation/, // pptx
    },
    {
        thumb: require('~/assets/exel-512.png').default,
        regex: /application\/vnd\.ms-excel/, // xls
    },
    {
        thumb: require('~/assets/exel-512.png').default,
        regex: /application\/vnd\.openxmlformats-officedocument\.spreadsheetml\.sheet/, // xlsx
    },
    {
        thumb: require('~/assets/word-3-512.png').default,
        regex: /application\/msword/, // doc
    },
    {
        thumb: require('~/assets/word-3-512.png').default,
        regex: /application\/vnd\.openxmlformats-officedocument\.wordprocessingml\.document/, // docx
    },
    {
        thumb: require('~/assets/powerpoint-3-512.png').default,
        regex: /application\/vnd\.oasis\.opendocument\.presentation/, // odp
    },
    {
        thumb: require('~/assets/exel-512.png').default,
        regex: /application\/vnd\.oasis\.opendocument\.spreadsheet/, // ods
    },
    {
        thumb: require('~/assets/word-3-512.png').default,
        regex: /application\/vnd\.oasis\.opendocument\.text/, // odt
    },
    {
        thumb: require('~/assets/word-3-512.png').default,
        regex: /application\/vnd\.sun\.xml\.writer/, // sxw
    },
    {
        thumb: require('~/assets/exel-512.png').default,
        regex: /application\/vnd\.sun\.xml\.calc/, // sxc
    },
    {
        thumb: require('~/assets/powerpoint-3-512.png').default,
        regex: /application\/vnd\.sun\.xml\.draw/, // sxd
    },
];

export const resolveImageUrl = (relativeUrl, width) => {
    if (width) {
        return urljoin(REACT_APP_URL_IMAGE, String(width), relativeUrl);
    }
    return urljoin(REACT_APP_URL_IMAGE, relativeUrl);
};

export const resolveStorageUrl = relativeUrl => {
    return urljoin(REACT_APP_URL_STORAGE, relativeUrl);
};

export const getPreviewByType = mimetype => {
    const type = typesRegex.find(t => t.regex.test(mimetype));
    if (type) {
        return type.thumb;
    }
    return require('~/assets/file-4-512.png');
};

export const resolvePreview = (relativeUrl, mimetype, width) => {
    if (imageTypesRegex.test(mimetype)) {
        return resolveImageUrl(relativeUrl, !mimetype.startsWith(svgType) ? width : null);
    }
    return getPreviewByType(mimetype);
};

export const loadImage = url => {
    return new Promise((resolve, reject) => {
        try {
            const img = new Image();
            img.onload = event => {
                resolve(img);
            };
            img.onerror = error => {
                reject(error);
            };
            img.src = url;
        } catch (ex) {
            reject(ex);
        }
    });
};

export const loadFilePreview = file => {
    return new Promise((resolve, reject) => {
        try {
            const url = URL.createObjectURL(file);
            loadImage(url)
                .then(img => resolve({
                    url,
                    img,
                }))
                .catch(reject);
        } catch (ex) {
            reject(ex);
        }
    });
};

export const convertAnexoToFile = (anexo, width) => {
    if (!anexo) return null;
    const lastModifiedDate = moment(anexo.created_at).toDate();
    const url = resolveStorageUrl(anexo.caminho);
    const urlPreview = resolvePreview(anexo.caminho, anexo.mimetype, width);
    return {
        id: anexo.id,
        uid: url,
        url,
        urlPreview,
        lastModifiedDate,
        lastModified: lastModifiedDate.getTime(),
        name: anexo.nome_original || anexo.nome,
        size: anexo.tamanho,
        type: anexo.mimetype,
    };
};
