import React, { useCallback } from 'react';

import { RadiusBottomrightOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { initializeApp } from 'firebase/app';
import {
    getToken, getMessaging,
    isSupported, onMessage,
} from 'firebase/messaging';
import { useHistory } from 'react-router-dom';

import useAxios from '~/hooks/use-axios';
import useDidMountAndUpdate from '~/hooks/use-did-mount-and-update';

import Button from './Button';
import styles from './FirebaseConfig.module.scss';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
};

const NotificationCard = ({ payload, history }) => {
    const getNotificationUrl = data => {
        switch (data.NotificationType) {
            case 'Event':
                return `/app/events/${data.EventId}`;
            case 'Checkin':
                return '/app/checkins';
            case 'SystemEvent':
                return '/app/system';
            default:
                return '/app/events';
        }
    };

    const onClickNotification = useCallback(() => {
        history.push(getNotificationUrl(payload.data));
    }, [history, payload]);

    const renderViewButton = () => {
        return (
            <Button onClick={onClickNotification}>Visualizar</Button>
        );
    };

    return (
        <div>
            <div className={styles.notificationBody}>
                {payload.data.Body}
            </div>
            {renderViewButton()}
        </div>
    );
};

const FirebaseConfig = () => {
    const history = useHistory();
    const api = useAxios();

    const getServiceWorker = useCallback(async () => {
        const serviceWorker = await window.navigator.serviceWorker.getRegistration('/firebase-push-notification-scope');
        if (serviceWorker) return serviceWorker;

        return window.navigator.serviceWorker.register(
            '/firebase-messaging-sw.js',
            { scope: '/firebase-push-notification-scope' },
        );
    }, []);

    const initliazeFirebase = useCallback(async () => {
        try {
            const isSupportedBrowser = await isSupported();
            if (!isSupportedBrowser) return;

            // eslint-disable-next-line no-undef
            const permission = await Notification.requestPermission();
            if (permission !== 'granted') return;

            const firebaseApp = initializeApp(firebaseConfig);
            const messaging = getMessaging(firebaseApp);
            const serviceWorker = await getServiceWorker();

            const token = await getToken(messaging, {
                vapidKey: process.env.REACT_APP_FB_VAPID_KEY,
                serviceWorker,
            });

            await api.post('/FirebaseNotificationToken/UpsertFirebaseToken', {
                firebaseToken: token,
            });

            onMessage(messaging, payload => {
                notification.info({
                    message: payload.data.Title,
                    icon: payload.data.icon,
                    description: (
                        <NotificationCard payload={payload} history={history} />
                    ),
                    placement: RadiusBottomrightOutlined,
                });
            });
        } catch (error) {
            console.warn('An error occurred while retrieving token. ', error);
        }
    }, [api, getServiceWorker, history]);

    useDidMountAndUpdate(() => {
        initliazeFirebase();
    }, []);

    return null;
};

export default FirebaseConfig;
