import { useEffect, useMemo, useState } from 'react';

import Axios from 'axios';

import { tokenInterceptor } from '~/helpers/axios-interceptor';

const { CancelToken } = Axios;

const useAxios = axiosOptions => {
    // transforma as options em um state para evitar mutabilidade
    const [options] = useState(axiosOptions);

    const source = useMemo(() => CancelToken.source(), []);

    const axios = useMemo(() => {
        const instance = Axios.create({
            ...options,
            cancelToken: source.token,
        });

        instance.interceptors.request.use(tokenInterceptor);

        return instance;
    }, [source, options]);

    useEffect(() => {
        return () => {
            source.cancel('Operation canceled by unmounted component.');
        };
    }, [source]);

    return axios;
};

export default useAxios;
