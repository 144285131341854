import moment from 'moment-timezone';

import keycloak from '~/keycloak';

// import store from '../redux/store';
import userPreferencesHelper from './user-preferences-helper';

export function tokenInterceptor(requestConfig) {
    // const { company } = store.getState();
    const { token } = keycloak;

    const company = userPreferencesHelper.getItem('COMPANY');

    if (token && !requestConfig.headers.Authorization) {
        // eslint-disable-next-line no-param-reassign
        requestConfig.headers.Authorization = `Bearer ${token}`;
        // eslint-disable-next-line no-param-reassign
        requestConfig.headers['x-company-id'] = company;
    }
    // eslint-disable-next-line no-param-reassign
    requestConfig.headers.timezone = moment.tz.guess();

    return requestConfig;
}
