import Keycloak from 'keycloak-js';
import urlJoin from 'url-join';

const keycloak = Keycloak({
    realm: process.env.REACT_APP_KEYCLOAK_REALM,
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID,
    url: urlJoin(process.env.REACT_APP_URL_KEYCLOAK),
    clientSecret: process.env.REACT_APP_KEYCLOAK_CLIENT_SECRETID,
});

export default keycloak;
