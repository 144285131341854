import React, { useMemo, memo } from 'react';

import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';

import { resolveImageUrl } from '~/helpers/storage-helper';

import styles from './UserMenu.module.scss';

const getFirstAndSecondName = fullName => {
    let formattedName = '';

    const parts = (fullName || '').trim().split(' ');
    if (parts.length < 2) {
        [formattedName] = parts;
    } else {
        formattedName = `${parts[0]} ${parts[1]}`;

    }

    if (formattedName.length > 18) {
        return `${formattedName.slice(0, 18)}...`;
    }

    return formattedName;
};

const UserMenu = memo(({
    photo, name, avatarSize,
    ...props
}) => {
    const photoUrl = useMemo(() => {
        return photo ? resolveImageUrl(photo, avatarSize * 2) : undefined;
    }, [photo, avatarSize]);

    const firstAndSecondName = useMemo(() => getFirstAndSecondName(name), [name]);

    return (
        <div className={styles.container}>
            <span className={styles.userName}>
                Olá, {firstAndSecondName}
            </span>
            <Avatar
                shape="circle"
                {...props}
                src={photoUrl}
                size={avatarSize}
                icon={<UserOutlined />}
                className={styles.userAvatar}
            />
        </div>
    );
});

UserMenu.propTypes = {
    avatarSize: PropTypes.number,
};

UserMenu.defaultProps = {
    avatarSize: 30,
};

export default UserMenu;
