import React from 'react';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { connect } from 'react-redux';

import useClassNames from '~/hooks/use-classnames';
import { actions } from '~/redux/ducks';

import styles from './MenuButton.module.scss';

const MenuButton = ({
    sidebarCollapsed, toggleSidebar, className, ...others
}) => {
    const classes = useClassNames([styles.button, className]);
    return (
        <Button
            {...others}
            className={classes}
            icon={sidebarCollapsed
                ? <RightOutlined />
                : <LeftOutlined />}
            onClick={toggleSidebar}
            type="default"
            shape="circle"
        />
    );
};

const mapStateToProps = ({ sidebarCollapsed }) => ({
    sidebarCollapsed,
});

const mapDispatchToProps = {
    toggleSidebar: actions.sidebar.toggleSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuButton);
