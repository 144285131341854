const colors = {
    background: '#F2F3F8',

    primary: '#4f55a7',
    primaryDark: '#020612',
    primaryLight: '#102c82',
    textOnPrimary: '#ffffff',

    colorButton: '#fff',

    accent: '#166ABF',
    accentDark: '#6d96b4',
    accentLight: '#a0faf4',
    textOnAccent: '#000000',

    lightBlue: '#D2EEFF',
    primaryGray: '#484848',
    secondaryGray: '#5A5A5A',

    textPrimaryDark: 'rgba(0, 0, 0, 0.87)', // 87%
    textSecondaryDark: 'rgba(0, 0, 0, 0.54)', // 54%
    textHalfDark: 'rgba(0, 0, 0, 0.50)', // 50%
    textDisabledDark: 'rgba(0, 0, 0, 0.38)', // 38%
    textHintDark: 'rgba(0, 0, 0, 0.38)', // 38%
    dividerDark: 'rgba(0, 0, 0, 0.12)', // 12%

    textPrimaryLight: 'rgb(255, 255, 255)', // 100%
    textSecondaryLight: 'rgba(255, 255, 255, 0.7)', // 70%
    textDisabledLight: 'rgba(255, 255, 255, 0.5)', // 50%
    textHintLight: 'rgba(255, 255, 255, 0.5)', // 50%
    dividerLight: 'rgba(255, 255, 255, 0.24)', // 24%

    colorError: '#ff4d4f',
    colorAlert: '#eaaf26',
    colorSuccess: '#52c41a',
    colorErrorBadge: '#962127',
    colorSuccessBadge: '#217537',

    colorSecondaryButton: '#b85f5f',

    link: '#166ABF',

    tagBackground: '#dddddd',

    backgroundDisabled: 'rgb(150,150,150)',

    transparent: 'rgba(0, 0, 0, 0)',
    emphasisColor: '#9e4fa7',
    borderColor: 'rgba(0,0,0,.06)',
};

export const getColorContrastYIQ = hexcolor => {
    if (!hexcolor) return '#000000';
    let color;
    switch (hexcolor.length) {
        case 7: // #ff00ff
            color = hexcolor.substr(1);
            break;
        case 4: // #f0f
            color = hexcolor.substr(1, 1).repeat(2)
                + hexcolor.substr(2, 1).repeat(2) + hexcolor.substr(3, 1).repeat(2);
            break;
        default:
            return '#ffffff';
    }
    const r = parseInt(color.substr(0, 2), 16);
    const g = parseInt(color.substr(2, 2), 16);
    const b = parseInt(color.substr(4, 2), 16);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? '#000000' : '#ffffff';
};

export default colors;
