import React, {
    Suspense, lazy,
} from 'react';

import { useKeycloak } from '@react-keycloak/web';
import { Switch, Route } from 'react-router-dom';

import PrivateRoute from '~/components/PrivateRoute';
import StageIndicator from '~/components/StageIndicator';
import useDidMountAndUpdate from '~/hooks/use-did-mount-and-update';
import { renderRoute } from '~/routes/manager';
import { allRoutes } from '~/routes/public';

import AppLayout from './AppLayout';

const NotFoundPage = lazy(() => import('~/domains/errors/NotFoundPage'));

const hideSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen');
    if (splashScreen) {
        setTimeout(() => {
            splashScreen.classList.add('splash-screen-hide');
            setTimeout(
                () => splashScreen.parentNode.removeChild(splashScreen),
                1000,
            );
        });
    }
};

const Layout = () => {
    const { initialized } = useKeycloak();

    useDidMountAndUpdate(() => {
        if (initialized) {
            hideSplashScreen();
        }
    }, [initialized]);

    if (!initialized) return 'Carregando usuário...';

    return (
        <Suspense fallback={<div>Carregando...</div>}>
            <Switch>
                {allRoutes.map((route, index) => {
                    return renderRoute(route, index);
                })}
                <PrivateRoute path="/app" component={AppLayout} />
                <Route component={NotFoundPage} />
            </Switch>
            <StageIndicator />
        </Suspense>
    );
};

export default Layout;

// TODO Mudar favicon qnd mandarem.
