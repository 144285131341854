import React, { lazy } from 'react';

import { Redirect } from 'react-router-dom';

import { MaterialIcons } from '~/components/Icon';

import { getRoutesArray } from './manager';

const DashboardPage = lazy(() => import('~/domains/dashboard/DashboardPage'));
const PeoplePage = lazy(() => import('~/domains/people/PeoplePage'));
const PersonPage = lazy(() => import('~/domains/people/PersonPage'));
const DependentPage = lazy(() => import('~/domains/people/DependentPage'));
const GroupsPage = lazy(() => import('~/domains/groups/GroupsPage'));
const GroupPage = lazy(() => import('~/domains/groups/GroupPage'));
const UsersCreateUpdatePage = lazy(() => import('~/domains/users/UserCreateUpdatePage'));
const EventsPage = lazy(() => import('~/domains/events/EventsPage'));
const SystemEventsPage = lazy(() => import('~/domains/system/SystemEventsPage'));
const UsersPage = lazy(() => import('~/domains/users/UsersPage'));
const ProfilePage = lazy(() => import('~/domains/profile/ProfilePage'));
const ReportsPage = lazy(() => import('~/domains/reports/ReportsPage'));
const CheckInListPage = lazy(() => import('~/domains/checkin/CheckInListPage'));
const ConfigurationsPage = lazy(() => import('~/domains/configurations/ConfigurationsPage'));
const CompaniesPage = lazy(() => import('~/domains/companies/CompaniesPage'));
const CompanyPage = lazy(() => import('~/domains/companies/CompanyPage'));

const ROUTES = [
    {
        path: '/',
        component: () => (
            <Redirect to="/app/dashboard" />
        ),
        exact: true,
    },
    {
        path: '/app/home',
        component: () => (
            <Redirect to="/app/dashboard" />
        ),
        exact: true,
    },
    {
        icon: {
            name: 'dashboard',
            family: MaterialIcons,
        },
        path: '/dashboard',
        menuKey: '/dashboard',
        component: DashboardPage,
        text: 'Dashboard',
        exact: true,
    },
    {
        icon: {
            name: 'person',
            family: MaterialIcons,
        },
        path: '/people',
        menuKey: '/people',
        component: PeoplePage,
        text: 'Pessoas',
        exact: true,
    },
    {
        path: '/people/new',
        menuKey: '/people',
        component: PersonPage,
    },
    {
        path: '/people/:personId',
        menuKey: '/people',
        component: PersonPage,
    },
    {
        path: '/dependents/new',
        menuKey: '/people',
        component: DependentPage,
    },
    {
        path: '/dependents/:dependentId',
        menuKey: '/people',
        component: DependentPage,
    },
    {
        path: '/groups',
        menuKey: '/groups',
        component: GroupsPage,
        exact: true,
        icon: {
            name: 'group',
            family: MaterialIcons,
        },
        text: 'Grupos',
    },
    {
        path: '/groups/new',
        menuKey: '/groups',
        exact: true,
        component: GroupPage,
    },
    {
        path: '/groups/:groupId',
        menuKey: '/groups',
        component: GroupPage,
    },
    {
        path: '/companies',
        menuKey: '/companies',
        text: 'Empresas',
        exact: true,
        component: CompaniesPage,
        icon: {
            name: 'business',
            family: MaterialIcons,
        },
    },
    {
        path: '/companies/new',
        menuKey: '/companies',
        component: CompanyPage,
    },
    {
        path: '/companies/:companyId',
        menuKey: '/companies',
        component: CompanyPage,
    },
    {
        path: '/events',
        menuKey: '/events',
        text: 'Eventos',
        exact: true,
        component: EventsPage,
        icon: {
            name: 'notifications',
            family: MaterialIcons,
        },
    },
    {
        path: '/events/:eventId',
        menuKey: '/events',
        component: EventsPage,
    },
    {
        path: '/reports',
        menuKey: '/reports',
        text: 'Relatórios',
        exact: true,
        component: ReportsPage,
        icon: {
            name: 'description',
            family: MaterialIcons,
        },
    },
    {
        path: '/checkins',
        menuKey: '/checkins',
        text: 'Check-ins',
        exact: true,
        component: CheckInListPage,
        icon: {
            name: 'locationOn',
            family: MaterialIcons,
        },
    },
    {
        path: '/system',
        menuKey: '/system',
        text: 'Sistema',
        exact: true,
        component: SystemEventsPage,
        icon: {
            name: 'displaySettings',
            family: MaterialIcons,
        },
    },
    {
        path: '/config',
        menuKey: '/config',
        text: 'Configurações',
        exact: true,
        component: ConfigurationsPage,
        icon: {
            name: 'settings',
            family: MaterialIcons,
        },
        permissions: ['op-admin'],
    },
    {
        path: '/users',
        menuKey: '/users',
        text: 'Usuários',
        exact: true,
        component: UsersPage,
        icon: {
            name: 'manageAccounts',
            family: MaterialIcons,
        },
        permissions: ['op-admin'],
    },
    {
        path: '/users/new',
        menuKey: '/users',
        exact: true,
        component: UsersCreateUpdatePage,
    },
    {
        path: '/users/:userId',
        menuKey: '/users',
        component: UsersCreateUpdatePage,
    },
    {
        path: '/profile',
        component: ProfilePage,
    },
];

export default ROUTES;

export const allRoutes = getRoutesArray({
    group: 'root',
    routes: ROUTES,
});
