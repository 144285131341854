import { Ability } from '@casl/ability';
import { handleAction, createAction, handleActions } from 'redux-actions';

const setUser = createAction('USERS/SET_USER');
const updateUser = createAction('USERS/UPDATE_USER');
const logoutUser = createAction('USERS/LOGOUT');
const setToken = createAction('USERS/SET_TOKEN');
const setTenancy = createAction('USERS/SET_TENANCY');
const setAbility = createAction('USERS/SET_ABILITY');
const setSkipRefreshToken = createAction('USERS/SET_SKIP_REFRESH_TOKEN');
const setIsNewSender = createAction('COMPANY/SET_NEWSENDER');
const setCompany = createAction('USERS/SET_COMPANY');

export const actions = {
    setUser,
    logoutUser,
    setToken,
    setTenancy,
    setAbility,
    updateUser,
    setSkipRefreshToken,
    setIsNewSender,
    setCompany,
};

export const userHandler = handleActions({
    [setUser]: (state, action) => ({
        ...action.payload,
    }),
    [updateUser]: (state, action) => ({
        ...state,
        ...action.payload,
    }),
}, null);

export const tokenHandler = handleAction(
    setToken,
    (state, action) => action.payload,
    null,
);

export const tenancyHandler = handleAction(
    setTenancy,
    (state, action) => action.payload,
    null,
);

export const isNewSenderHandler = handleAction(
    setIsNewSender,
    (state, action) => action.payload,
    null,
);

export const abilityHandler = handleAction(
    setAbility,
    (state, action) => action.payload,
    new Ability([]),
);

export const skipRefreshTokenHandler = handleAction(
    setSkipRefreshToken,
    (state, action) => action.payload || false,
    false,
);

export const companyHandler = handleAction(
    setCompany,
    (state, action) => action.payload,
    null,
);

export const reducers = {
    user: userHandler,
    token: tokenHandler,
    tenancy: tenancyHandler,
    ability: abilityHandler,
    skipRefreshToken: skipRefreshTokenHandler,
    setIsNewSender: isNewSenderHandler,
    company: companyHandler,
};
