import React, { memo, useCallback } from 'react';

import { useKeycloak } from '@react-keycloak/web';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import useDidMount from '~/hooks/use-did-mount';

const RedirectToLogin = () => {
    const { keycloak } = useKeycloak();
    useDidMount(() => {
        keycloak.login();
    });
    return null;
};

RedirectToLogin.propTypes = {
    location: PropTypes.string.isRequired,
};

const PrivateRoute = memo(({
    render, component, ...others
}) => {

    const { keycloak } = useKeycloak();
    const handleRedirectLogin = useCallback(() => {
        keycloak.login();
    }, [keycloak]);

    return (
        <Route
            {...others}
            render={keycloak.authenticated ? render : handleRedirectLogin}
            component={keycloak.authenticated ? component : RedirectToLogin}
        />
    );
});

export default PrivateRoute;
