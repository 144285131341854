import React from 'react';

import { Route } from 'react-router-dom';
import urlJoin from 'url-join';

import UserPreferences from '~/helpers/user-preferences-helper';
import keycloak from '~/keycloak';
// import store from '~/redux/store';

export const getRoutesArray = route => {
    if (route.group) {
        return route.routes.reduce((result, r) => {
            return [...result, ...getRoutesArray(r)];
        }, []);
    }
    return [route];
};

export const renderRoute = (route, index, prefix) => {
    const { component: Comp } = route;
    return (
        <Route
            key={route.path || index}
            path={prefix ? urlJoin(prefix, route.path) : route.path}
            exact={route.exact}
            component={Comp}
        />
    );
};

export const findParentGroups = (routeThree, route) => {
    const path = [];
    const getPath = (root, dest) => {
        if (root === dest) return true;
        if (root.group && root.routes.some(r => getPath(r, dest))) {
            path.push(root);
            return true;
        }
        return false;
    };
    getPath({
        group: 'root',
        routes: routeThree,
    }, route);
    return path;
};

export const hasRoutePermission = route => {
    if (!Array.isArray(route.permissions)) {
        return true;
    }
    const roles = keycloak.tokenParsed?.role_name;
    return route.permissions.some(permission => {
        return roles?.find(role => role === permission);
    });
};

export const hasPermissonMenu = routeId => {
    const objects = UserPreferences.getItem('USER_KEY')?.objects;

    if (routeId === null || routeId === undefined) {
        return true;
    }

    if (objects.length > 0) {
        for (let i = 0; i < objects.length; i++) {
            if (routeId === objects[i]) {
                return true;
            }
        }
        return false;
    }
    return false;
};

export const userAcceptedTerms = () => {
    const acceptedTerms = UserPreferences.getItem('USER_KEY')?.terms;
    if (acceptedTerms) {
        return true;
    }

    return false;
};

export const firstCompanyLogin = () => {
    const newSender = UserPreferences.getItem('IS_NEW');
    if (newSender) {
        return false;
    }

    return true;
};

export const getUserKey = () => {
    return UserPreferences.getItem('USER_KEY');
};

export const setUserKey = value => {
    return UserPreferences.setItem('USER_KEY', value);
};

export const getIsNew = () => {
    return UserPreferences.getItem('IS_NEW');
};

export const setIsNew = value => {
    return UserPreferences.setItem('IS_NEW', value);
};

export const getTenancy = () => {
    return UserPreferences.getItem('TENANCY');
};
