import React, { useMemo } from 'react';

import { Button as AntdButton } from 'antd';

import useClassNames from '~/hooks/use-classnames';
import colors from '~/values/colors';

import styles from './Button.module.scss';

const Button = ({
    style,
    className,
    backgroundColor,
    fontColor,
    borderRadius,
    disabled,
    type,
    ...others
}) => {
    const buttonStyle = useMemo(() => ({
        backgroundColor: disabled ? colors.backgroundDisabled : backgroundColor,
        color: fontColor,
        borderRadius,
        ...style,
    }), [backgroundColor, borderRadius, disabled, fontColor, style]);

    const classes = useClassNames([styles.button, className]);

    return (
        <AntdButton
            {...others}
            disabled={disabled}
            type={type}
            style={buttonStyle}
            className={classes}
        />
    );
};

Button.defaultProps = {
    backgroundColor: colors.accent,
    fontColor: colors.textPrimaryLight,
    borderRadius: 4,
    size: 'large',
    type: 'default',
};

Button.Group = AntdButton.Group;

export default Button;
