import React, {
    memo, useCallback,
} from 'react';

import { useKeycloak } from '@react-keycloak/web';
import {
    Layout,
    Popover, Divider,
} from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// import Select from '~/components/Select';
import UserMenu from '~/components/UserMenu';
// import { extractRequestError } from '~/helpers/error-helper';
import { connectScreenSize } from '~/helpers/screen-size-helper';
// import useAxios from '~/hooks/use-axios';
// import useDidMount from '~/hooks/use-did-mount';
import useScreen from '~/hooks/use-screen';
import thunks from '~/redux/thunks';

import styles from './AppHeaderBar.module.scss';
import MenuButton from './MenuButton';

const { Header } = Layout;

const AppHeaderBar = memo(({
    // setCompany, company,
    removeAuthenticationData,
}) => {
    // const axios = useAxios();
    const { keycloak } = useKeycloak();
    const { sizes: { isDesktop } } = useScreen();
    // const [companyList, setCompanyList] = useState([]);

    // const currentCompany = useMemo(() => ({ key: company.id, label: company.name }), [company]);

    const { name } = keycloak.tokenParsed;

    const handleLogout = useCallback(() => {
        removeAuthenticationData();
    }, [removeAuthenticationData]);

    // const requestMyCompanies = useCallback(async () => {
    //     try {
    //         const { data } = await axios.get('/api/core/companies', { params: { active: true } });

    //         setCompanyList(data.map(item => ({ key: item.id, label: item.name, item })));
    //     } catch (err) {
    //         extractRequestError(
    //             err,
    //             'Erro ao buscar empresas',
    //         );
    //     }
    // }, [axios]);

    const renderUserOptions = () => {
        return (
            <div>
                <div className={styles.profile}>
                    <b>Opções</b>
                    <Divider className={styles.optionDivider} />
                    <Link
                        to="/app/profile"
                    >
                        Perfil
                    </Link>
                    <Divider className={styles.optionDivider} />
                    <a onClick={handleLogout} role="button" tabIndex={0}>Sair</a>
                </div>
            </div>
        );
    };

    // useDidMount(() => {
    //     requestMyCompanies();
    // });

    // const handleCompanyChange = useCallback(selectedOption => {
    //     setCompany(selectedOption.item);
    //     window.location.reload();
    // }, [setCompany]);

    return (
        <Header className={styles.header}>
            <div className={styles.headerContainer}>
                {isDesktop ? null : (<MenuButton className={styles.menuButton} />)}
                {/* <div className={styles.tenancyContainer}>
                    <Select
                        options={companyList}
                        value={currentCompany}
                        onChange={handleCompanyChange}
                    />
                </div> */}
                <div className={styles.avatarContainer}>
                    <Divider type="vertical" className={styles.menuDivider} />
                    <Popover
                        placement="bottomRight"
                        content={renderUserOptions()}
                        trigger="focus"
                    >
                        <a onClick={null} role="button" tabIndex="-1">
                            <UserMenu
                                name={name}
                                photo={null}
                            />
                        </a>
                    </Popover>
                </div>
            </div>
        </Header>
    );

});

AppHeaderBar.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

const mapStateToProps = ({ user, company }) => ({
    user, company,
});

const mapDispatchToProps = ({
    removeAuthenticationData: thunks.user.removeAuthenticationData,
    setCompany: thunks.user.setCompany,
});

export default connect(mapStateToProps, mapDispatchToProps)(connectScreenSize(withRouter(AppHeaderBar)));
