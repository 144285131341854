import React from 'react';

import FirebaseConfig from './components/FirebaseConfig';
import Providers from './components/Providers';
import Layout from './layouts/Layout';

const App = () => {

    return (
        <Providers>
            <Layout />
            <FirebaseConfig />
        </Providers>
    );
};

export default App;
