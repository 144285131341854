import axios from 'axios';

import { createAbility } from '~/helpers/ability-helper';
import UserPreferences from '~/helpers/user-preferences-helper';
import keycloak from '~/keycloak';
import { actions } from '~/redux/ducks/user';

const COMPANY = 'COMPANY';

export const setCompany = company => {
    return async (dispatch, getState) => {
        await UserPreferences.setItem(COMPANY, company.id);
        dispatch(actions.setCompany(company));
    };
};

export const removeAuthenticationData = () => {
    return async (dispatch, getState) => {
        await axios.delete('/FirebaseNotificationToken/DeleteFirebaseTokenByUserId', {
            Authorization: `Bearer ${keycloak.token}`,
        });
        keycloak.logout();
        UserPreferences.clear();
        dispatch(actions.logoutUser());
        dispatch(actions.setAbility(createAbility([])));
    };
};
export const loadAuthenticationData = () => {
    return async (dispatch, getState) => {
        const { data } = await axios.get('/api/core/companies', null, {
            Authorization: `Bearer ${keycloak.token}`,
        });

        const companyId = UserPreferences.getItem(COMPANY);
        let selectedCompany;
        if (companyId) {
            selectedCompany = data.find(company => company.id === companyId);
        } else {
            [selectedCompany] = data;
            await UserPreferences.setItem(COMPANY, selectedCompany.id);
        }

        dispatch(actions.setCompany(selectedCompany));
    };
};
