import React from 'react';

import { Redirect } from 'react-router-dom';

import { getRoutesArray } from './manager';

const ROUTES = [
    {
        path: '/',
        component: () => (
            <Redirect to="/app" />
        ),
        exact: true,
    },
];

export const allRoutes = getRoutesArray({
    group: 'root',
    routes: ROUTES,
});

export default ROUTES;
